import React, { useState, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductPage.css"; 

const ProductPage = ({ images, price, currency_sign }) => {
  const [mainImageIndex, setMainImageIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false); 
  const mainSlider = useRef(null); 
  const thumbnailSlider = useRef(null); 
  const lightboxMainSlider = useRef(null);
  const lightboxThumbnailSlider = useRef(null); 

  const handleMainImageChange = (index) => {
    setMainImageIndex(index);
    if (thumbnailSlider.current) {
      thumbnailSlider.current.slickGoTo(index); 
    }
    if (lightboxThumbnailSlider.current) {
      lightboxThumbnailSlider.current.slickGoTo(index); 
    }
  };

  const handleThumbnailClick = (index) => {
    setMainImageIndex(index);
    mainSlider.current.slickGoTo(index); 
    if (lightboxMainSlider.current) {
      lightboxMainSlider.current.slickGoTo(index); 
    }
  };

  const openLightbox = () => {
    setIsLightboxOpen(true);
  };

  const closeLightbox = () => {
    setIsLightboxOpen(false);
  };

  const mainImageSettings = images && images.length > 1 ? {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => handleMainImageChange(next),
    ref: mainSlider,
    prevArrow: (
      <button className="slick-prev" aria-label="Previous" type="button"></button>
    ),
    nextArrow: (
      <button className="slick-next" aria-label="Next" type="button"></button>
    ),
  } : {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    beforeChange: (current, next) => handleMainImageChange(next),
    arrows: false,
    ref: mainSlider,
  };

  const thumbnailSettings = {
    infinite: images.length > 5,  
    speed: 500,
    slidesToShow: Math.min(images.length, 5), 
    slidesToScroll: 1,
    focusOnSelect: true,
    ref: thumbnailSlider,
    variableWidth: true,  
    adaptiveHeight: true,
    arrows: images.length > 5, 
    prevArrow: images.length > 5 ? (
      <button className="slick-prev" aria-label="Previous" type="button"></button>
    ) : null,
    nextArrow: images.length > 5 ? (
      <button className="slick-next" aria-label="Next" type="button"></button>
    ) : null,
    asNavFor: mainSlider.current,
  };
  
  const lightboxThumbnailSettings = images && images.length > 11 ? {
    infinite: false,
    speed: 500,
    slidesToShow: 11, 
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: true,
    prevArrow: (
      <button className="slick-prev" aria-label="Previous" type="button"></button>
    ),
    nextArrow: (
      <button className="slick-next" aria-label="Next" type="button"></button>
    ),
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  }: {
    infinite: false,
    speed: 500,
    slidesToShow: images.length, 
    slidesToScroll: 1,
    focusOnSelect: true,
    adaptiveHeight: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: Math.min(images.length, 8),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991, 
        settings: {
          slidesToShow: Math.min(images.length, 5),
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 650,
        settings: {
          slidesToShow: Math.min(images.length, 3),
          slidesToScroll: 1,
        },
      },
    ],
  
  };
  
  return (
    <div className="product-page-container">
      <div className="main-image-container">
        {images.length > 1 ? (
          <Slider {...mainImageSettings} ref={mainSlider}>
            {images.map((image, index) => (
              <div key={index} className="main-image-wrapper">
                <img
                  src={image.picture.url.large}
                  alt={`Images ${index}`}
                  className="main-image"
                  onClick={openLightbox} 
                  style={ image.rotate ? { transform: `rotate(${image.rotate}deg)` } : {}
                  }
                />
              </div>
            ))}
          </Slider>
        ) : (
          images.map((image, index) => (
            <div key={index} className="main-image-wrapper">
              <img
                src={image.picture.url.large}
                alt={`Images ${index}`}
                className="main-image"
                onClick={openLightbox} 
                style={ image.rotate ? { transform: `rotate(${image.rotate}deg)` } : {}
                }
              />
            </div>
          ))
        )}
        <span className="price1">
          {currency_sign} {price}
        </span>
      </div>
      <div className="thumbnail-container">
        <Slider {...thumbnailSettings}>
          {images.map((image, index) => (
            <div
              key={index}
              className={`thumbnail-wrapper ${mainImageIndex === index ? "selected" : ""}`}
              onClick={() => handleThumbnailClick(index)}
            >
              <img
                src={image.picture.url.small}
                alt={`Images ${index}`}
                className="thumbnail-image"
                style={ image.rotate ? { transform: `rotate(${image.rotate}deg)` } : {}
                }
              />
              {mainImageIndex !== index && <div className="thumbnail-overlay" />}
            </div>
          ))}
        </Slider>
      </div>


      {isLightboxOpen && (
        <div className="lightbox-overlay" onClick={closeLightbox}>
          <div className="lightbox-content" onClick={(e) => e.stopPropagation()}>
            <Slider {...mainImageSettings} ref={lightboxMainSlider}>
              {images.map((image, index) => (
                <div key={index} className="main-lightbox-image-wrapper">
                  <img
                    src={image.picture.url.large}
                    alt={`Image ${index}`}
                    className="lightbox-main-image"
                    style={ image.rotate ? { transform: `rotate(${image.rotate}deg)` } : {}}
                  />
                </div>
              ))}
            </Slider>


            <Slider {...lightboxThumbnailSettings} ref={lightboxThumbnailSlider}>
              {images.map((image, index) => (
                <div
                  key={index}
                  className={`thumbnail-lightbox-wrapper mt-5 ${mainImageIndex === index ? "selected" : ""}`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  <img
                    src={image.picture.url.small}
                    alt={`Images ${index}`}
                    className="thumbnail-lightbox-image"
                  />
                </div>
              ))}
            </Slider>
          
          </div>
          <button className="lightbox-close" onClick={closeLightbox}>
              &times;
            </button>
        </div>
      )}
    </div>
  );
};

export default ProductPage;